<template>
  <div class="home">
    <!-- 导航栏 -->
    <NavBarHome v-if="navBarStyle === '1'" />
    <NavBar v-else />

    <!-- banner -->
    <div class="header" id="header">
      <div class="header-main-title">
        <span>全方位区块链解决方案与服务提供商</span>
      </div>
      <div class="header-sub-title">
        <div class="line"></div>
        <div class="text">链接未来 赋能百业</div>
        <div class="line"></div>
      </div>
      <!-- <el-carousel
        trigger="click"
        height="540px"
        :autoplay="false"
        arrow="never"
        ref="bannerCarousel"
        @change="changeBanner"
      >
        <el-carousel-item>
          <div class="banner1">
            <div class="banner1-main-title">
              <span>全方位区块链解决方案与服务提供商</span>
            </div>
            <div class="banner1-sub-title">
              <div class="line"></div>
              <div class="text">链接未来 赋能百业</div>
              <div class="line"></div>
            </div>
          </div>
        </el-carousel-item>
        <el-carousel-item>
          <div class="banner2">
            <div class="banner2-main">
              <p>众享区块链BAC平台多功能版本正式上线</p>
              <button>立即体验</button>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel> -->
    </div>

    <!-- 技术体系 -->
    <div class="technology-system">
      <div class="commom-title technology-system-title">
        区块链全生态技术体系
      </div>
      <div class="technology-system-content">
        <div class="content-item">
          <div class="content-item-img">
            <img
              src="https://image.peerfintech.cn/peerfintech_website_v2/home/technology-system/content-item-1.png"
            />
          </div>
          <div class="content-item-desc">
            <div class="content-item-desc-title">行业解决方案</div>
            <div class="content-item-desc-line"></div>
            <div class="content-item-desc-text">
              <p>金融行业</p>
              <p>供应链金融</p>
              <p>政府政务</p>
              <p>智慧城市</p>
              <p>农业溯源</p>
              <p>物联网区块链</p>
              <p>电力区块链</p>
            </div>
          </div>
        </div>
        <div class="content-item">
          <div class="content-item-img">
            <img
              src="https://image.peerfintech.cn/peerfintech_website_v2/home/technology-system/content-item-2.png"
            />
          </div>
          <div class="content-item-desc">
            <div class="content-item-desc-title">区块链标准化产品</div>
            <div class="content-item-desc-line"></div>
            <div class="content-item-desc-text">
              <p>存证系统</p>
              <p>溯源系统</p>
              <p>积分系统</p>
              <p>数据共享系统</p>
              <p>供应链金融系统</p>
            </div>
          </div>
        </div>
        <div class="content-item">
          <div class="content-item-img">
            <img
              src="https://image.peerfintech.cn/peerfintech_website_v2/home/technology-system/content-item-3.png"
            />
          </div>
          <div class="content-item-desc">
            <div class="content-item-desc-title">区块链技术应用平台</div>
            <div class="content-item-desc-line"></div>
            <div class="content-item-desc-text content-item-desc-text3">
              <p>全球首款区块链智能应用平台WisChain</p>
              <p>区块链可视化应用平台ChainViewer</p>
              <p>众享区块链超级工作站</p>
            </div>
          </div>
        </div>
        <div class="content-item">
          <div class="content-item-img">
            <img
              src="https://image.peerfintech.cn/peerfintech_website_v2/home/technology-system/content-item-4.png"
            />
          </div>
          <div class="content-item-desc">
            <div class="content-item-desc-title">底层技术产品</div>
            <div class="content-item-desc-line"></div>
            <div class="content-item-desc-text">
              <p>区块链安全通信技术</p>
              <p>区块链分布式存储技术</p>
              <p>全球首款区块链数据库技术平台ChainSQL</p>
              <p>基于Hyperledger Fabric企业级区块链技术平台</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 核心技术 -->
    <div class="core-technology" id="core-technology">
      <div class="commom-title core-technology-title">核心技术</div>
      <div class="core-technology-content">
        <div class="core-technology-content-left">
          <img
            src="https://image.peerfintech.cn/peerfintech_website_v2/home/core-technology/core.png"
            class="core-technology-img"
          />
        </div>
        <div class="core-technology-content-right">
          <div
            class="core-technology-item"
            v-for="(item, index) in coreTechnologyList"
            :key="index"
          >
            <img :src="item.url" class="core-technology-item-img" />
            <p class="core-technology-item-text">{{ item.text }}</p>
          </div>
        </div>
      </div>
    </div>
    <!-- 解决方案 -->
    <div class="solution">
      <div class="commom-title solution-title">多领域的解决方案</div>
      <div class="solution-content">
        <div class="solution-outerbox">
          <div
            class="solution-innerbox"
            :style="{
              left: -firstSolutionIndex * 17.75 + '%',
            }"
          >
            <div
              :class="{
                'solution-content-active-item': index === activeSolutionIndex,
                'solution-content-item': index !== activeSolutionIndex,
              }"
              @mouseenter="enterSolution(index)"
              v-for="(item, index) in solutionList"
              :key="index"
              @click="clickSolution(item.link)"
            >
              <div class="solution-content-item-img">
                <img
                  :src="
                    activeSolutionIndex === index ? item.urlHover : item.url
                  "
                />
              </div>

              <div class="solution-content-item-title">{{ item.title }}</div>
              <div class="solution-content-item-text">
                <div v-for="(text, index1) in item.content" :key="index1">
                  <p>{{ text }}</p>
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="arrow-left" @click="solutionPrev">
          <i class="el-icon-arrow-left"></i>
        </div>
        <div class="arrow-right" @click="solutionNext">
          <i class="el-icon-arrow-right"></i>
        </div>
      </div>
    </div>
    <!-- 产品 -->
    <div class="product" id="product">
      <div class="commom-title product-title">区块链成熟产品</div>
      <div class="product-content">
        <div class="product-menu">
          <div
            class="menu-item"
            :class="{ 'active-menu': index == activeProductIndex }"
            v-for="(item, index) in productList"
            :key="index"
            @click="activeProductIndex = index"
            @mouseenter="hoverProductIndex = index"
            @mouseleave="hoverProductIndex = null"
          >
            <img
              :src="
                index == activeProductIndex || index == hoverProductIndex
                  ? item.activeIcon
                  : item.icon
              "
            />
            <span>{{ item.tab }}</span>
            <div class="line"></div>
          </div>
        </div>
        <div class="product-desc">
          <div
            class="desc-item"
            v-for="(item, index) in productList[activeProductIndex].list"
            :key="index"
            @click="learnMoreRroduct(item.link)"
          >
            <div class="desc-title">{{ item.title }}</div>
            <div class="desc-text">{{ item.desc }}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 案例 -->
    <div class="case">
      <div class="commom-title case-title">传统行业+区块链典型案例</div>
      <div class="case-content">
        <div class="case-content-header">
          <img src="@/assets/img/home/case/img-header.png" />
        </div>
        <div class="case-content-main">
          <div class="case-carousel">
            <el-carousel
              height="320px"
              :autoplay="false"
              arrow="never"
              indicator-position="outside"
            >
              <el-carousel-item
                v-for="(caseCarousel, index) in caseList"
                :key="index"
              >
                <div class="carousel-item">
                  <div
                    class="item"
                    v-for="(item, index) in caseCarousel[caseIndex]"
                    :key="index"
                  >
                    <div class="item-img">
                      <img :src="item.url" />
                    </div>
                    <div class="item-text">{{ item.text }}</div>
                  </div>
                </div>
              </el-carousel-item>
            </el-carousel>
          </div>
          <div class="case-menu">
            <div class="case-menu-item" @mouseenter="caseIndex = 0">
              <div
                class="menu-icon"
                :class="{ 'active-menu-icon': caseIndex === 0 }"
              ></div>
              <div class="menu-img">
                <img src="@/assets/img/home/case/finance.png" />
              </div>
            </div>
            <div class="case-menu-item" @mouseenter="caseIndex = 1">
              <div
                class="menu-icon"
                :class="{ 'active-menu-icon': caseIndex === 1 }"
              ></div>
              <div class="menu-img">
                <img src="@/assets/img/home/case/gov.png" />
              </div>
            </div>
            <div
              class="case-menu-item"
              style="margin-bottom: 36px"
              @mouseenter="caseIndex = 2"
            >
              <div
                class="menu-icon"
                :class="{ 'active-menu-icon': caseIndex === 2 }"
              ></div>
              <div class="menu-img">
                <img
                  src="https://image.peerfintech.cn/peerfintech_website_v2/home/case/enterprise.png"
                />
              </div>
            </div>
            <div class="case-total">
              <span class="num">0{{ caseIndex + 1 }}/</span>
              <span class="total">03</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 产学研合作 -->
    <div class="cooperation">
      <div class="commom-title cooperation-title">产学研合作</div>
      <div class="cooperation-content">
        <div class="cooperation-content-item">
          <div class="cooperation-img">
            <img
              src="https://image.peerfintech.cn/peerfintech_website_v2/home/cooperation/cooperation-1.png"
            />
          </div>
          <p class="cooperation-text">
            2017年与南京大学信息管理学院成立区块链联合实验室
          </p>
        </div>
        <div class="cooperation-content-item">
          <div class="cooperation-img">
            <img
              src="https://image.peerfintech.cn/peerfintech_website_v2/home/cooperation/cooperation-2.png"
            />
          </div>
          <p class="cooperation-text">
            2019年与东南大学江北新区创新研究院共建“东大江北众享区块链联合创新中心”
          </p>
        </div>
      </div>
    </div>
    <!-- 新闻 -->
    <div class="news">
      <div class="commom-title news-title">新闻资讯</div>
      <div class="news-content" v-if="newsList.length > 0">
        <div class="news-left">
          <div
            class="left-img"
            :style="{
              'background-image': `url(${newsList[activeNewIndex].big_url})`,
            }"
          ></div>

          <div class="left-content">
            <div class="title" :title="newsList[activeNewIndex].title">
              {{ newsList[activeNewIndex].title }}
            </div>
            <div class="text">
              {{ newsList[activeNewIndex].content }}
            </div>
            <div class="date">
              {{ newsList[activeNewIndex].create_time | filterDate() }}
            </div>
            <a :href="newsList[activeNewIndex].link" target="_blank"
              ><button class="btn">了解更多</button></a
            >
          </div>
        </div>
        <div class="news-right">
          <div class="arrow-allow" @click="newsPrev">
            <i class="el-icon-arrow-up"></i>
          </div>
          <div class="swiper-container">
            <div class="swiper-wrapper">
              <div
                class="swiper-slide"
                v-for="(item, index) in newsList"
                :key="index"
                :data-index="index"
                :class="{ 'active-swiper-slide': index === activeNewIndex }"
              >
                <div class="item-img">
                  <img :src="item.small_url" />
                  <div class="item-title">
                    {{ item.title }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="arrow-allow" @click="newsNext">
            <i class="el-icon-arrow-down"></i>
          </div>
        </div>
      </div>
      <div v-else style="height: 70px"></div>
    </div>

    <!-- 伙伴 -->
    <div class="partner">
      <div class="commom-title partner-title">客户&合作伙伴</div>
      <div class="partner-content">
        <el-carousel
          height="492px"
          :autoplay="false"
          :loop="false"
          arrow="never"
        >
          <el-carousel-item>
            <div class="swiper-item">
              <img src="@/assets/img/home/partner/partner-7@2x.png" />
              <img src="@/assets/img/home/partner/partner-18@2x.png" />
              <img src="@/assets/img/home/partner/partner-30@2x.png" />
              <img src="@/assets/img/home/partner/partner-13@2x.png" />
              <img src="@/assets/img/home/partner/partner-8@2x.png" />
              <img src="@/assets/img/home/partner/partner-4@2x.png" />
              <img src="@/assets/img/home/partner/partner-35@2x.png" />
              <img src="@/assets/img/home/partner/partner-29@2x.png" />
              <img src="@/assets/img/home/partner/partner-9@2x.png" />
              <img src="@/assets/img/home/partner/partner-10@2x.png" />
              <img src="@/assets/img/home/partner/partner-5@2x.png" />
              <img src="@/assets/img/home/partner/partner-32@2x.png" />
              <img src="@/assets/img/home/partner/partner-40@2x.png" />
              <img src="@/assets/img/home/partner/partner-33@2x.png" />
              <img src="@/assets/img/home/partner/partner-42@2x.png" />
            </div>
          </el-carousel-item>
          <el-carousel-item>
            <div class="swiper-item">
              <img src="@/assets/img/home/partner/partner-11@2x.png" />
              <img src="@/assets/img/home/partner/partner-36@2x.png" />
              <img src="@/assets/img/home/partner/partner-2@2x.png" />
              <img src="@/assets/img/home/partner/partner-3@2x.png" />
              <img src="@/assets/img/home/partner/partner-19@2x.png" />
              <img src="@/assets/img/home/partner/partner-28@2x.png" />
              <img src="@/assets/img/home/partner/partner-43@2x.png" />
              <img src="@/assets/img/home/partner/partner-16@2x.png" />
              <img src="@/assets/img/home/partner/partner-1@2x.png" />
              <img src="@/assets/img/home/partner/partner-31@2x.png" />
              <img src="@/assets/img/home/partner/partner-27@2x.png" />
              <img src="@/assets/img/home/partner/partner-34@2x.png" />
              <img src="@/assets/img/home/partner/partner-38@2x.png" />
              <img src="@/assets/img/home/partner/partner-20@2x.png" />
              <img src="@/assets/img/home/partner/partner-17@2x.png" />
            </div>
          </el-carousel-item>
          <el-carousel-item>
            <div class="swiper-item">
              <img src="@/assets/img/home/partner/partner-14@2x.png" />
              <img src="@/assets/img/home/partner/partner-41@2x.png" />
              <img src="@/assets/img/home/partner/partner-37@2x.png" />
              <img src="@/assets/img/home/partner/partner-6@2x.png" />
              <img src="@/assets/img/home/partner/partner-39@2x.png" />
              <img src="@/assets/img/home/partner/partner-15@2x.png" />
              <img src="@/assets/img/home/partner/partner-12@2x.png" />
              <img src="@/assets/img/home/partner/partner-21@2x.png" />
              <img src="@/assets/img/home/partner/partner-22@2x.png" />
              <img src="@/assets/img/home/partner/partner-23@2x.png" />
              <img src="@/assets/img/home/partner/partner-24@2x.png" />
              <img src="@/assets/img/home/partner/partner-25@2x.png" />
              <img src="@/assets/img/home/partner/partner-26@2x.png" />
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>

    <!-- 加入我们 -->
    <div class="join-us">
      <div class="join-us-title">链接未来 赋能百业</div>
    </div>
    <!-- footer -->
    <Footer />
  </div>
</template>

<script>
import NavBar from "@/components/NavBar";
import NavBarHome from "@/components/NavBarHome";
import Footer from "@/components/Footer";
import axios from "@/api/axios.js";
import dayjs from "dayjs";
import { scrollMixin } from "@/mixins/scroll.js";
import Swiper from "swiper";
import ScrollReveal from "scrollreveal";
export default {
  name: "Home",
  components: {
    NavBar,
    NavBarHome,
    Footer,
  },
  mixins: [scrollMixin],
  data() {
    return {
      coreTechnologyList: [
        {
          url: require("@/assets/img/home/core-technology/item-1.png"),
          text: "同态加密",
        },
        {
          url: require("@/assets/img/home/core-technology/item-2.png"),
          text: "区块链数据同步",
        },
        {
          url: require("@/assets/img/home/core-technology/item-3.png"),
          text: "动态加密隧道",
        },
        {
          url: require("@/assets/img/home/core-technology/item-4.png"),
          text: "哈希寻址技术",
        },
        {
          url: require("@/assets/img/home/core-technology/item-5.png"),
          text: "多分片随机传输技术",
        },
        {
          url: require("@/assets/img/home/core-technology/item-6.png"),
          text: "区块链分片共识算法",
        },
        {
          url: require("@/assets/img/home/core-technology/item-7.png"),
          text: "区块链的数据库事务操作技术",
        },
        {
          url: require("@/assets/img/home/core-technology/item-8.png"),
          text: "区块链分层设计技术",
        },
        {
          url: require("@/assets/img/home/core-technology/item-9.png"),
          text: "国密算法",
        },
        {
          url: require("@/assets/img/home/core-technology/item-10.png"),
          text: "联盟治理",
        },
        {
          url: require("@/assets/img/home/core-technology/item-11.png"),
          text: "随机数生成和应用方法技术",
        },
        {
          url: require("@/assets/img/home/core-technology/item-12.png"),
          text: "区块链数据隐私隔离",
        },
        {
          url: require("@/assets/img/home/core-technology/item-13.png"),
          text: "P2P组网",
        },
        {
          url: require("@/assets/img/home/core-technology/item-14.png"),
          text: "零知识证明",
        },
      ],
      solutionList: [
        {
          url: require("@/assets/img/home/solution/solution-6.png"),
          urlHover: require("@/assets/img/home/solution/solution-hover-6.png"),
          title: "供应链金融+区块链",
          content: [
            "众享区块链供应链金融平台围绕核心企业，覆盖其上下游中小微企业、商业银行、保理公司等资金端及物流、仓储企业。平台核心功能为整合贸易基础信息的聚合，确保账款信息来源的真实性、平台操作的信息不可篡改、操作的过程信息透明公开、操作成功的信息可溯源查询。",
          ],
          link: "/solution/supplyChain",
        },
        {
          url: require("@/assets/img/home/solution/solution-8.png"),
          urlHover: require("@/assets/img/home/solution/solution-hover-8.png"),
          title: "溯源+区块链",
          content: [
            "方案以区块链技术作为支撑并结合物联网、大数据、人工智能等先进技术，将商品从原材料采购、加工、生产、质检、物流、经销商、零售商的全流程信息记录上链，平台包含了信息录入、溯源查询、监管报送等主要功能。",
          ],
          link: "/solution/traceability",
        },
        {
          url: require("@/assets/img/home/solution/solution-2.png"),
          urlHover: require("@/assets/img/home/solution/solution-hover-2.png"),
          title: "数据共享+区块链",
          content: [
            "方案以联盟链技术为支撑，通过数据加密和签名技术，使双方实现数据的精准交换和共享，平台包含数据上传、数据合约、数据共享和数据查询等主要功能。",
          ],
          link: "/solution/share",
        },
        {
          url: require("@/assets/img/home/solution/solution-3.png"),
          urlHover: require("@/assets/img/home/solution/solution-hover-3.png"),
          title: "存证+区块链",
          content: [
            "利用区块链技术实现数据的可信存储，使用数据多级加密和多维权限控制技术，解决传统数据易伪造、易篡改、难溯源、难校验的问题。同时平台支持一键仲裁/一键诉讼，仲裁机构或法院被授权后可直接从链上查看数据，大大提高了办事效率。",
          ],
          link: "/solution/certificate",
        },
        {
          url: require("@/assets/img/home/solution/solution-10.png"),
          urlHover: require("@/assets/img/home/solution/solution-hover-10.png"),
          title: "线上交易平台+区块链",
          content: [
            "基于区块链技术打造可靠的分布式资产管理平台，包含资产发行及管理、自动撮合管理、信息认证、充值提现、账单查询、人员管理等功能。",
            "方案适用于数字资产管理、能源管理、版权管理、奢侈品管理、艺术品管理、精准扶贫等众多场景。",
          ],
          link: "/solution/transaction",
        },
        {
          url: require("@/assets/img/home/solution/solution-1.png"),
          urlHover: require("@/assets/img/home/solution/solution-hover-1.png"),
          title: "司法+区块链",
          content: [
            "“汇存”区块链电子数据存证平台是上海市徐汇公证处与北京众享比特科技有限公司共同打造的基于区块链技术的专业存证产品，将公证机构的公证力与区块链的技术可信相结合，让电子证据成为被法院认可的有效证据。",
          ],
          link: "/solution/justice",
        },
        {
          url: require("@/assets/img/home/solution/solution-4.png"),
          urlHover: require("@/assets/img/home/solution/solution-hover-4.png"),
          title: "清结算+区块链",
          content: [
            "方案以联盟链为支撑，覆盖清算结算全流程管理，包含了数据登记、清算管理、结算管理、数据查询统计等主要功能。",
            "方案旨在解决机构间的信任问题，利用分布式信任机制，实现实时的清算和结算，使资金和资产需要锁定的时间减少，提升价值的流动。",
          ],
          link: "/solution/settlement",
        },
        {
          url: require("@/assets/img/home/solution/solution-5.png"),
          urlHover: require("@/assets/img/home/solution/solution-hover-5.png"),
          title: "资产证券化+区块链",
          content: [
            "方案以联盟链为支撑，覆盖资产证券化全流程，包含了资产包管理、资产发行管理、 资产风险管理、现金流管理和资产挂牌管理等主要功能。",
          ],
          link: "/solution/assetSecuritization",
        },

        {
          url: require("@/assets/img/home/solution/solution-7.png"),
          urlHover: require("@/assets/img/home/solution/solution-hover-7.png"),
          title: "扶贫+区块链",
          content: [
            "区块链扶贫将传统的人工管理、资金管理方式与区块链技术应用有机结合，让扶贫款项沿着规定的用途、条件、时间安全、透明、精准地下发。此举既保证了扶贫款项使用过程中的规范性、合理性，又简化了扶贫基金的使用流程，实现扶贫资金的下拨不走样、不截留、不挪用，遏制了扶贫造假等现象。",
          ],
          link: "/solution/poverty",
        },
        {
          url: require("@/assets/img/home/solution/solution-9.png"),
          urlHover: require("@/assets/img/home/solution/solution-hover-9.png"),
          title: "积分商城+区块链",
          content: [
            "方案应用区块链技术，构建公开、透明的积分商城。商城可发行区块链积分，并支持多商铺多积分模式，构建“电商+积分+区块链”生态系统。",
            "方案旨在打造一个公开透明、灵活配置、有效提升各方效率、助力商户突破营销瓶颈的区块链积分商城。",
          ],
          link: "/solution/point",
        },
      ],
      activeSolutionIndex: 0,
      firstSolutionIndex: 0,
      productList: [
        {
          tab: "底层架构",
          icon: require("@/assets/img/home/product/icon-1.png"),
          activeIcon: require("@/assets/img/home/product/acative-icon-1.png"),
          list: [
            {
              title: "ChainSQL",
              desc: "ChainSQL是全球首个基于区块链的数据库应用平台，综合了区块链技术与传统数据库的优点，不仅具有区块链的分布式、多中心化、可审计的特性，同时兼备传统数据库的快速查询、数据结构优化的特性。",
              link: "/product/chainSQL",
            },
            {
              title: "众享联盟链",
              desc: "众享联盟链提供高品质企业级区块链服务，采用六层系统架构。基础设施层既能利用云平台设施，也支持物理机本地部署。数据库层支持对接主流传统数据库，降低企业使用区块链的门槛。",
              link: "/product/league",
            },
            {
              title: "众享Fabric产品",
              desc: "Fabric是一个面向联盟链的区块链平台，可以通过在这个平台上部署特定的链码(Chaincode)实现业务逻辑。",
              link: "/product/fabric",
            },
          ],
        },
        {
          tab: "开发者平台",
          icon: require("@/assets/img/home/product/icon-2.png"),
          activeIcon: require("@/assets/img/home/product/acative-icon-2.png"),
          list: [
            {
              title: "ChainSQL功能调用平台",
              desc: "ChainSQL功能调用平台可以界面化使用ChainSQL的全部功能。目前，ChainSQL产品所有的功能均通过代码形式进行展现，为方便用户更加直观的使用ChainSQL的功能，众享科技推出了ChainSQL功能调用平台。",
              link: "/product/functionAdjustment",
            },
          ],
        },
        {
          tab: "区块链BaaS",
          icon: require("@/assets/img/home/product/icon-3.png"),
          activeIcon: require("@/assets/img/home/product/acative-icon-3.png"),
          list: [
            {
              title: "WisChain区块链应用平台",
              desc: "WisChain是众享科技自主研发的区块链智能部署运维平台，旨在构建高效、安全、智能、可扩展的企业级区块链架构体系。采用创新的一键部署、多框架支持及多链技术，实现不同企业区块链网络的快速搭建、可视化维护、多链连接及扩展。",
              link: "/product/wisChain",
            },
          ],
        },
        {
          tab: "硬件产品",
          icon: require("@/assets/img/home/product/icon-4.png"),
          activeIcon: require("@/assets/img/home/product/acative-icon-4.png"),
          list: [
            {
              title: "众享区块链超级工作站",
              desc: "众享区块链超级工作站是众享科技自主研发的软硬云一体化区块链应用产品，实现物理主机+区块链网络+应用系统+运维监控全场景服务。",
              link: "/product/superworkstation",
            },
          ],
        },
        {
          tab: "五大平台",
          icon: require("@/assets/img/home/product/icon-5.png"),
          activeIcon: require("@/assets/img/home/product/acative-icon-5.png"),
          list: [
            {
              title: "众享区块链溯源平台",
              desc: "众享区块链溯源平台针对不同标的的特性提供闭环溯源解决方案，结合物联网、大数据、人工智能等先进技术，关键环节可采用物联网技术保证信息的自动生成和上链，降低人力成本的同时为上链数据的真实性提供技术保证手段。",
              link: "/product/traceability",
            },
            {
              title: "众享区块链供应链金融平台",
              desc: "众享区块链供应链金融平台针对供应链上小微企业融资难困境，依托区块链上核心企业的信任传递，围绕核心企业及上下游多级供应链企业，借助保险、信托、仓储、物流等服务商共同打造供应链金融产业生态闭环。",
              link: "/product/finance",
            },
            {
              title: "众享区块链积分平台",
              desc: "众享区块链积分平台为企业用户提供可快速落地、高价值的基于区块链的积分平台解决方案。该平台能够提高积分的通兑性和流通性，进而提高积分的使用价值，可灵活应用于对外营销或企业内部管理。",
              link: "/product/point",
            },
            {
              title: "众享区块链数据共享平台",
              desc: "众享区块链数据共享平台是一个提供数据资产和服务的联盟链生态系统。平台连接数据生产者和消费者，提供定价的许可框架。任何上链的数据均需通过联盟方制定的数据合约以保障数据的安全与合规，打破数据孤岛并均衡各参与方的数据。",
              link: "/product/share",
            },
            {
              title: "众享区块链存证平台",
              desc: "众享区块链存证平台联合司法公证处、司法鉴定所、仲裁机构、法院等机构建立联盟链，以电子数据为操作对象，将证据固化在平台上，实现电子数据的采集、存证、取证、公证、鉴定、调解、仲裁等全流程服务。",
              link: "/product/certificate",
            },
          ],
        },
        {
          tab: "区块链运维",
          icon: require("@/assets/img/home/product/icon-6.png"),
          activeIcon: require("@/assets/img/home/product/acative-icon-6.png"),
          list: [
            {
              title: "众享区块链浏览器",
              desc: "在ChainSQL区块链浏览器上，可动态展示账户关联信息，可实时更新区块状态与交易详情，可有效监控链上节点，从而帮助用户更加简单、便捷、快速的查询、监控、运维区块链。",
              link: "/product/browser",
            },
            {
              title: "众享区块链监控平台",
              desc: "众享区块链监控平台致力于为使用者搭建可视化的运维与监控平台。通过该平台，使用者可实时监控节点、区块、合约、tps等链上数据以及相关业务数据仪表。监控系统具有自动监测及适配机制，如出现异常情况，系统自动告警，减轻运维人员负担 。",
              link: "/product/monitor",
            },
          ],
        },
        {
          tab: "区块链治理",
          icon: require("@/assets/img/home/product/icon-7.png"),
          activeIcon: require("@/assets/img/home/product/acative-icon-7.png"),
          list: [
            {
              title: "众享联盟链治理平台",
              desc: "众享联盟链治理平台是众享比特基于Fabric研发的一组套件，可以有效解决Fabric框架本身配置复杂、使用繁琐的问题。",
              link: "/product/governance",
            },
          ],
        },
      ],
      activeProductIndex:
        window.sessionStorage.getItem("activeProductIndex") || 0,
      hoverProductIndex: null,
      newsList: [],
      activeNewIndex: 0,
      mySwiper: null,
      caseList: [
        [
          [
            {
              url: require("@/assets/img/home/case/finance-1.png"),
              text: "「贸易金融平台」",
            },
            {
              url: require("@/assets/img/home/case/finance-2.png"),
              text: "「BCLC信用证传输系统」",
            },
            {
              url: require("@/assets/img/home/case/finance-3.png"),
              text: "「票据跨行贴现项目」",
            },
            {
              url: require("@/assets/img/home/case/finance-4.png"),
              text: "「联盟清分链及风险评估结果共享链」",
            },
            {
              url: require("@/assets/img/home/case/finance-5.png"),
              text: "「贷后资金管理BaaS平台」",
            },
            {
              url: require("@/assets/img/home/case/finance-6.png"),
              text: "「贸易金融跨行交易平台」",
            },
          ],
          [
            {
              url: require("@/assets/img/home/case/gov-1.png"),
              text: "「区块链贸易直通车」",
            },
            {
              url: require("@/assets/img/home/case/gov-2.png"),
              text: "「区块链运维监控及服务平台」",
            },
            {
              url: require("@/assets/img/home/case/gov-3.png"),
              text: "「能源大数据联盟链平台」",
            },
            {
              url: require("@/assets/img/home/case/gov-4.png"),
              text: "「区块链智能仓储管理系统」",
            },
            {
              url: require("@/assets/img/home/case/gov-5.png"),
              text: "「“我的南京”APP碳积分项目」",
            },
            {
              url: require("@/assets/img/home/case/gov-6.png"),
              text: "「区块链+物联网电子仓单系统」",
            },
          ],
          [
            {
              url: require("@/assets/img/home/case/enterprise-1.png"),
              text: "「区块链黑名单共享平台」",
            },
            {
              url: require("@/assets/img/home/case/enterprise-2.png"),
              text: "「青耕鸟校园疫情防控系统」",
            },
            {
              url: require("@/assets/img/home/case/enterprise-3.png"),
              text: "「区块链产业生态技术研究」",
            },
            {
              url: require("@/assets/img/home/case/enterprise-4.png"),
              text: "「青岛橡胶非标转标仓单平台」",
            },
            {
              url: require("@/assets/img/home/case/enterprise-5.png"),
              text: "「开放式医药产业联盟链平台——仙茱链」",
            },
            {
              url: require("@/assets/img/home/case/enterprise-6.png"),
              text: "「区块链政府及垂直行业应用研发技术支撑」",
            },
          ],
        ],
        [
          [
            {
              url: require("@/assets/img/home/case/finance-7.png"),
              text: "「区块链金融应用试点」",
            },
            {
              url: require("@/assets/img/home/case/finance-8.png"),
              text: "「应收账款流转区块链平台」",
            },
            {
              url: require("@/assets/img/home/case/finance-9.png"),
              text: "「基于区块链技术的小微企业智能金融信贷支持平台」",
            },
            {
              url: require("@/assets/img/home/case/finance-10.png"),
              text: "「客户数字资产流通及管理平台」",
            },
            {
              url: require("@/assets/img/home/case/finance-11.png"),
              text: "「人行反洗钱基础设施优化项目」",
            },
            {
              url: require("@/assets/img/home/case/finance-12.png"),
              text: "「地方金融区块链BaaS平台」",
            },
          ],
          [
            {
              url: require("@/assets/img/home/case/gov-7.png"),
              text: "「区块链电子档案国家试点项目」",
            },
            {
              url: require("@/assets/img/home/case/gov-8.png"),
              text: "「智慧城市区块链辅助平台」",
            },
            {
              url: require("@/assets/img/home/case/gov-9.png"),
              text: "「江苏省市场监管区块链技术平台」",
            },
            {
              url: require("@/assets/img/home/case/gov-10.png"),
              text: "「数字云党建系统」",
            },
          ],
          [
            {
              url: require("@/assets/img/home/case/enterprise-7.png"),
              text: "「国新央企金融服务联盟链」",
            },
            {
              url: require("@/assets/img/home/case/enterprise-8.png"),
              text: "「区块链供应链金融平台」",
            },
            {
              url: require("@/assets/img/home/case/enterprise-9.png"),
              text: "「茶叶溯源及产业数字金融」",
            },
            {
              url: require("@/assets/img/home/case/enterprise-10.png"),
              text: "「乌兰浩特卷烟厂基于在线检测装置大数据分析效率优化及提升项目」",
            },
            {
              url: require("@/assets/img/home/case/enterprise-11.png"),
              text: "「气囊区块链溯源项目」",
            },
          ],
        ],
      ],
      caseIndex: 0,
      // textColor: "#FFFFFF",
    };
  },
  methods: {
    //轮播图切换
    // changeBanner(index) {
    //   if (index === 0) {
    //     this.textColor = "#FFFFFF";
    //   } else {
    //     this.textColor = "#000000";
    //   }
    // },

    //解决方案
    enterSolution(index) {
      if (index <= this.firstSolutionIndex + 4) {
        this.activeSolutionIndex = index;
      }
    },
    solutionPrev() {
      if (this.firstSolutionIndex > 0) {
        this.firstSolutionIndex = this.firstSolutionIndex - 1;
        if (this.activeSolutionIndex > this.firstSolutionIndex + 4) {
          this.activeSolutionIndex = this.firstSolutionIndex + 4;
        }
      }
    },
    solutionNext() {
      if (this.firstSolutionIndex < this.solutionList.length - 5)
        this.firstSolutionIndex = this.firstSolutionIndex + 1;
      if (this.activeSolutionIndex < this.firstSolutionIndex) {
        this.activeSolutionIndex = this.firstSolutionIndex;
      }
    },
    clickSolution(link) {
      this.$router.push(link);
    },
    //产品
    learnMoreRroduct(link) {
      var reg = new RegExp(/^http/);
      if (reg.test(link)) {
        window.open(link, "_blank");
      } else {
        this.$router.push(link);
      }
    },
    //合作伙伴
    PartnerPrev() {
      this.$refs.partnerCarousel.prev();
    },
    PartnerNext() {
      this.$refs.partnerCarousel.next();
    },

    //新闻资讯
    newsPrev() {
      this.mySwiper.swipePrev();
    },
    newsNext() {
      this.mySwiper.swipeNext();
    },
  },
  mounted() {
    //滚动特效
    ScrollReveal().reveal(
      [
        document.querySelector(".technology-system-title"),
        document.querySelector(".core-technology-title"),
        document.querySelector(".solution-title"),
        document.querySelector(".product-title"),
        document.querySelector(".case-title"),
        document.querySelector(".cooperation-title"),
        document.querySelector(".partner-title"),
        document.querySelector(".news-title"),
      ],
      { duration: 1000, distance: "50px", origin: "bottom" }
    );
    ScrollReveal().reveal(
      [
        document.querySelector(".technology-system-content"),
        document.querySelector(".core-technology-content"),
        document.querySelector(".solution-content"),
        document.querySelector(".product-content"),
        document.querySelector(".case-content"),
        document.querySelector(".cooperation-content"),
        document.querySelector(".news-content"),
        document.querySelector(".partner-content"),
      ],
      { duration: 2000, distance: "50px", origin: "bottom", reset: true }
    );
    ScrollReveal().reveal([document.querySelector(".join-us-title")], {
      duration: 1000,
      distance: "200px",
      origin: "bottom",
      rotate: {
        x: 20,
        z: -20,
      },
    });

    let that = this;
    //从其他页面跳转到该页面时，根据hash值跳转到指定位置
    if (this.$route.hash) {
      var id = this.$route.hash.substring(1);
      var div = document.getElementById(id);
      if (div) {
        div.scrollIntoView();
      } else {
        document.getElementById("header").scrollIntoView();
      }
    } else {
      document.getElementById("header").scrollIntoView();
    }

    //请求新闻数据
    axios
      .get("/news/query")
      .then((res) => {
        this.newsList = res.data.payload;
        this.$nextTick(() => {
          this.mySwiper = new Swiper(".swiper-container", {
            mode: "vertical", // 垂直切换选项
            loop: true, // 循环模式选项
            slidesPerView: 3, //设置slider容器能够同时显示的slides数量(carousel模式)。
            mousewheelControl: true, //开启鼠标滚轮控制Swiper切换。可设置鼠标选项，或true使用默认值。
            moveStartThreshold: 10,
            onSlideClick: function (swiper) {
              that.activeNewIndex = Number(
                swiper.clickedSlide.getAttribute("data-index")
              );
              for (
                let i = 0;
                i < document.querySelectorAll(".swiper-slide").length;
                i++
              ) {
                if (
                  document
                    .querySelectorAll(".swiper-slide")
                    [i].getAttribute("data-index") ===
                  swiper.clickedSlide.getAttribute("data-index")
                ) {
                  document.querySelectorAll(".swiper-slide")[i].className =
                    "swiper-slide active-swiper-slide";
                } else {
                  document.querySelectorAll(".swiper-slide")[i].className =
                    "swiper-slide";
                }
              }
            },
          });
        });
      })
      .catch(() => {});
  },
  watch: {
    $route(to) {
      if (to.hash) {
        var id = to.hash.substring(1);
        var div = document.getElementById(id);
        if (div) {
          div.scrollIntoView();
        } else {
          document.getElementById("header").scrollIntoView();
        }
      } else {
        document.getElementById("header").scrollIntoView();
      }
    },
  },
  filters: {
    filterDate: (value) => {
      if (value) {
        return dayjs(value).format("YYYY-MM-DD");
      } else {
        return "-";
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    // 缓存product
    switch (from.name) {
      case "Product-FunctionAdjustment":
        window.sessionStorage.setItem("activeProductIndex", 1);
        break;
      case "Product-WisChain":
        window.sessionStorage.setItem("activeProductIndex", 2);
        break;
      case "Product-Superworkstation":
        window.sessionStorage.setItem("activeProductIndex", 3);
        break;
      case "Product-Finance":
        window.sessionStorage.setItem("activeProductIndex", 4);
        break;
      case "Product-Certificate":
        window.sessionStorage.setItem("activeProductIndex", 4);
        break;
      case "Product-Share":
        window.sessionStorage.setItem("activeProductIndex", 4);
        break;
      case "Product-Traceability":
        window.sessionStorage.setItem("activeProductIndex", 4);
        break;
      case "Product-Point":
        window.sessionStorage.setItem("activeProductIndex", 4);
        break;
      case "Product-Browser":
        window.sessionStorage.setItem("activeProductIndex", 5);
        break;
      case "Product-Monitor":
        window.sessionStorage.setItem("activeProductIndex", 5);
        break;
      case "Product-Governance":
        window.sessionStorage.setItem("activeProductIndex", 6);
        break;
      default:
        window.sessionStorage.setItem("activeProductIndex", 0);
    }
    next();
  },
};
</script>

<style lang="scss" scoped>
.home {
  background-color: #fff;
  .content-width {
    // width: 62.5%;
    // min-width: 1200px;
    width: 1200px;
    margin: 0px auto;
  }
  .commom-title {
    font-size: 32px;
    font-family: "PingFangSC-Medium";
    color: #000000;
    line-height: 45px;
    text-align: center;
    padding-bottom: 50px;
  }

  // .header {
  //   width: 100%;
  //   height: 540px;
  //   .banner1 {
  //     width: 100%;
  //     height: 540px;
  //     background: url("~@/assets/img/home/banner.png") center/100% 100%
  //       no-repeat;
  //     .banner1-main-title {
  //       padding-top: 202px;
  //       font-size: 46px;
  //       font-family: "PingFangSC-Medium";
  //       line-height: 65px;
  //       color: #ffffff;
  //       padding-bottom: 30px;
  //       text-align: center;
  //       span {
  //         padding-left: 80px;
  //       }
  //     }
  //     .banner1-sub-title {
  //       display: flex;
  //       width: 816px;
  //       align-items: center;
  //       margin: auto;
  //       padding-left: 80px;
  //       .line {
  //         flex: 1;
  //         height: 1px;
  //         background-color: #fff;
  //       }
  //       .text {
  //         font-size: 24px;
  //         color: #ffffff;
  //         line-height: 33px;
  //         padding: 0px 32px;
  //       }
  //     }
  //   }

  //   .banner2 {
  //     width: 100%;
  //     height: 540px;
  //     background: url("~@/assets/img/home/banner-2.png") center/100% 100%
  //       no-repeat;
  //     display: flex;
  //     align-items: center;
  //     .banner2-main {
  //       @extend .content-width;
  //       p {
  //         width: 328px;
  //         font-size: 36px;
  //         font-family: "PingFangSC-Medium";
  //         color: #000000;
  //         line-height: 52px;
  //       }
  //       button {
  //         width: 172px;
  //         height: 54px;
  //         background: #0075ff;
  //         margin-top: 52px;
  //         font-size: 20px;
  //         font-family: "PingFangSC-Medium";
  //         color: #ffffff;
  //         line-height: 54px;
  //         text-align: center;
  //         border: none;
  //         cursor: pointer;
  //         border-radius: 4px;
  //         &:hover {
  //           background: #0077ffce;
  //         }
  //       }
  //     }
  //   }
  //   ::v-deep .el-carousel__indicators--horizontal {
  //     bottom: 90px;
  //   }

  //   ::v-deep .el-carousel__button {
  //     background-color: #d8dadf;
  //     opacity: 1;
  //     height: 4px;
  //     width: 40px;
  //   }
  //   ::v-deep .el-carousel__indicator.is-active .el-carousel__button {
  //     opacity: 1;
  //     background-color: #0075ff;
  //   }
  // }
  .header {
    width: 100%;
    height: 540px;
    background: url("https://image.peerfintech.cn/peerfintech_website_v2/home/banner.png")
      center/cover no-repeat;
    .header-main-title {
      padding-top: 202px;
      font-size: 46px;
      font-family: "PingFangSC-Medium";
      line-height: 65px;
      color: #ffffff;
      padding-bottom: 30px;
      text-align: center;
      span {
        padding-left: 80px;
      }
    }
    .header-sub-title {
      display: flex;
      width: 816px;
      align-items: center;
      margin: auto;
      padding-left: 80px;
      .line {
        flex: 1;
        height: 1px;
        background-color: #fff;
      }
      .text {
        font-size: 24px;
        color: #ffffff;
        line-height: 33px;
        padding: 0px 32px;
      }
    }
  }

  .technology-system {
    width: 100%;
    background: url("https://image.peerfintech.cn/peerfintech_website_v2/home/technology-system/technology-system-bg.png")
      center/cover no-repeat;
    padding: 70px 0px;
    .technology-system-content {
      @extend .content-width;
      display: flex;
      justify-content: space-between;
      .content-item {
        width: 23.5%;
        background: #ffffff;
        box-shadow: 0px 6px 24px 0px rgba(0, 0, 0, 0.08);
        .content-item-img {
          width: 100%;
          height: 222px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .content-item-desc {
          padding: 24px 32px 0px;
          .content-item-desc-title {
            font-family: "PingFangSC-Medium";
            color: #000000;
            line-height: 26px;
            font-size: 18px;
          }
          .content-item-desc-line {
            width: 40px;
            height: 2px;
            background: #0075ff;
            margin: 12px 0px 16px;
          }
          .content-item-desc-text {
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            align-content: space-between;
            width: 100%;
            height: 180px;
            position: relative;
            p {
              font-size: 14px;
              font-weight: 400;
              color: rgba(0, 0, 0, 0.65);
              line-height: 20px;
              margin-bottom: 12px;
            }
          }
          .content-item-desc-text3 {
            p {
              white-space: nowrap;
            }
          }
        }
        &:hover {
          transform: scale(1.05);
          transition: all 0.5s;
        }
      }
    }
  }
  .core-technology {
    background: #242833;
    padding: 70px 0px;
    .core-technology-title {
      color: #ffffff;
      padding-bottom: 42px;
    }
    .core-technology-content {
      @extend .content-width;
      display: flex;
      height: 303px;
      .core-technology-content-left {
        width: 318px;
        height: 303px;
        background: #262b38;
        box-shadow: 6px 6px 24px 0px rgba(0, 0, 0, 0.4);
        padding: 12px;
        margin-right: 16px;
        .core-technology-img {
          width: 100%;
          height: 100%;
        }
      }
      .core-technology-content-right {
        flex: 1;
        display: flex;
        justify-content: space-between;
        align-content: space-between;
        flex-wrap: wrap;
        .core-technology-item {
          width: 12.7%;
          margin-top: 8px;
          height: 131px;
          background: #262b38;
          box-shadow: 6px 6px 24px 0px rgba(0, 0, 0, 0.4);
          text-align: center;
          .core-technology-item-img {
            width: 51px;
            height: 51px;
            margin-top: -8px;
          }
          .core-technology-item-text {
            margin-top: 24px;
            font-size: 13px;
            font-weight: 400;
            color: #ffffff;
            line-height: 22px;
            padding: 0px 7px;
          }
          &:hover {
            transform: translateY(-8px);
            transition: all 0.5s;
          }
        }
      }
    }
  }
  .solution {
    padding: 70px 0px;
    @extend .content-width;
    .solution-content {
      width: 100%;
      position: relative;
      .solution-outerbox {
        width: 100%;
        height: 357px;
        overflow: hidden;
        position: relative;
        .solution-innerbox {
          display: flex;
          position: absolute;
          width: 188.75%; // (348+213*9)/1200
          transition: all 0.5s;
          .solution-content-item {
            width: 9.4%; // 213/(348+213*9)
            height: 150px;
            margin-top: 135px;
            border-left: 1px solid #d9d9d9;
            &:hover {
              cursor: pointer;
            }
            .solution-content-item-img {
              width: 64px;
              height: 64px;
              margin: 24px 0px 0px 40px;
              img {
                width: 100%;
                height: 100%;
              }
            }
            .solution-content-item-title {
              font-size: 18px;
              font-family: "PingFangSC-Medium";
              color: #000000;
              line-height: 26px;
              margin: 12px 0px 0px 40px;
            }
            .solution-content-item-text {
              display: none;
            }
          }
          .solution-content-active-item {
            width: 15.364%; // 348/(348+213*9)
            height: 357px;
            background: #0075ff;
            box-shadow: 0px 6px 24px 0px rgba(0, 0, 0, 0.12);
            border-radius: 0px 100px 0px 0px;
            margin-top: 0px;
            transition: height 0.3s;
            &:hover {
              cursor: pointer;
            }
            .solution-content-item-img {
              width: 64px;
              height: 64px;
              margin: 50px 0px 0px 40px;
              img {
                width: 100%;
                height: 100%;
              }
            }
            .solution-content-item-title {
              font-size: 18px;
              font-family: "PingFangSC-Medium";
              color: #fff;
              line-height: 26px;
              margin: 12px 0px 15px 40px;
            }
            .solution-content-item-text {
              display: block;
              margin: 0px 40px;
              font-size: 14px;
              color: #ffffff;
              line-height: 20px;
            }
          }
        }
      }

      .arrow-left,
      .arrow-right {
        width: 60px;
        height: 60px;
        position: absolute;
        background-color: #e1e1e1;
        border-radius: 50%;
        top: 50%;
        transform: translateY(-50%);
        line-height: 60px;
        text-align: center;
        color: #fff;
        font-size: 30px;
        cursor: pointer;
        .el-icon-arrow-left,
        .el-icon-arrow-right {
          font-weight: bold;
        }
        &:hover {
          background-color: #0075ff;
        }
      }
      .arrow-left {
        left: -98px;
      }
      .arrow-right {
        right: -98px;
      }
    }
  }
  .product {
    padding: 70px 0px;
    background: #f8fafc;
    .product-content {
      @extend .content-width;
      display: flex;
      .product-menu {
        width: 18.3%;
        padding: 24px 0px 29px;
        background: linear-gradient(360deg, #ffffff 0%, #f6f9fd 100%);
        box-shadow: 0px 20px 24px 0px rgba(0, 58, 126, 0.04);
        border: 3px solid #ffffff;
        .menu-item {
          height: 60px;
          width: 100%;
          display: flex;
          align-items: center;
          color: #485f7b;
          &:hover {
            color: #0075ff;
            cursor: pointer;
          }
          img {
            width: 24px;
            height: 24px;
            margin-left: 22px;
            margin-right: 12px;
          }
          span {
            font-size: 16px;
            font-family: "PingFangSC-Medium";
            font-weight: 500;
            line-height: 22px;
          }
        }
        .active-menu {
          color: #0075ff;
          position: relative;
          .line {
            width: 4px;
            height: 40px;
            background: #0075ff;
            position: absolute;
            left: 0px;
            top: 10px;
          }
        }
      }
      .product-desc {
        flex: 1;
        background: linear-gradient(360deg, #fbfdff 0%, #f6f9fd 100%);
        box-shadow: 0px 20px 24px 0px rgba(0, 58, 126, 0.04),
          inset 24px 24px 24px -10px rgba(0, 58, 126, 0.08);
        padding: 32px;
        .desc-item {
          padding: 24px 30px;
          margin-bottom: 20px;
          border: 2px solid transparent;
          cursor: pointer;
          &:last-child {
            margin-bottom: 0px;
          }
          &:hover {
            background: linear-gradient(180deg, #f6f9fd 0%, #ffffff 100%);
            box-shadow: 0px 10px 24px 0px rgba(0, 58, 126, 0.06);
            border: 2px solid #ffffff;
          }
          .desc-title {
            font-size: 20px;
            font-family: "PingFangSC-Medium";
            color: #000000;
            line-height: 28px;
            margin-bottom: 12px;
          }
          .desc-text {
            font-size: 15px;
            color: rgba(0, 0, 0, 0.65);
            line-height: 21px;
          }
        }
      }
    }
  }
  .case {
    padding: 70px 0px 91px;
    background: #242833;
    .case-title {
      color: #ffffff;
    }
    .case-content {
      @extend .content-width;
      .case-content-header {
        width: 100%;
        height: 36px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .case-content-main {
        height: 379px;
        background: #0075ff;
        position: relative;
        .case-carousel {
          position: absolute;
          top: -36px;
          left: 20.6%;
          width: 75%;
          height: 320px;
          background: #ffffff;
          box-shadow: 0px 0px 24px 0px rgba(0, 30, 65, 0.12);
          .carousel-item {
            display: flex;
            flex-wrap: wrap;
            .item {
              width: 33.33%;
              height: 160px;
              padding-top: 18px;
              .item-img {
                height: 60px;
                img {
                  width: 100%;
                  height: 100%;
                }
              }
              .item-text {
                padding: 16px 20px 0px;
                font-size: 16px;
                font-family: "PingFangSC-Medium";
                color: #000000;
                line-height: 22px;
                text-align: center;
              }
            }
          }
          ::v-deep .el-carousel__indicator--horizontal {
            padding-top: 40px;
          }
          ::v-deep .el-carousel__button {
            height: 5px;
            background-color: rgba(255, 255, 255, 0.36);
            opacity: 1;
          }
          ::v-deep .el-carousel__indicator.is-active .el-carousel__button {
            opacity: 1;
            background-color: #ffffff;
          }
        }
        .case-menu {
          width: 20.6%;
          padding: 50px 0px 0px 50px;
          .case-menu-item {
            display: flex;
            align-items: center;
            margin-bottom: 42px;
            .menu-icon {
              width: 5px;
              height: 4px;
              background: #ffffff;
            }
            .active-menu-icon {
              width: 64px;
              transition: all 0.5s;
            }
            .menu-img {
              width: 36px;
              height: 36px;
              margin-left: 12px;
              img {
                width: 100%;
                height: 100%;
              }
            }
            &:hover {
              cursor: pointer;
            }
          }
          .case-total {
            .num {
              font-size: 50px;
              font-weight: 700;
              color: #ffffff;
              line-height: 69px;
            }
            .total {
              font-weight: 700;
              font-size: 32px;
              color: rgba(255, 255, 255, 0.4);
            }
          }
        }
      }
    }
  }

  .cooperation {
    padding: 70px 0px;
    background: url("https://image.peerfintech.cn/peerfintech_website_v2/home/cooperation/cooperation-bg.png")
      center/cover no-repeat;
    .cooperation-content {
      @extend .content-width;
      display: flex;
      justify-content: space-between;
      .cooperation-content-item {
        width: 48.67%;
        background: #ffffff;
        box-shadow: 0px 6px 24px 0px rgba(0, 0, 0, 0.12);
        border-radius: 16px;
        padding: 9px 9px 32px;

        .cooperation-img {
          width: 100%;
          border-radius: 16px;
          overflow: hidden;
          img {
            width: 100%;
            &:hover {
              transform: scale(1.05);
              transition: all 0.5s;
            }
          }
        }
        .cooperation-text {
          padding: 9px 71px 0px;
          text-align: center;
          font-size: 22px;
          font-family: "PingFangSC-Medium";
          color: #000000;
          line-height: 30px;
        }
      }
    }
  }
  .partner {
    background: url("https://image.peerfintech.cn/peerfintech_website_v2/home/partner/partner-bg.png")
      center/cover no-repeat;
    padding-top: 70px;
    height: 510px;
    .partner-title {
      padding-bottom: 0px;
    }
    .partner-content {
      transform: scale(0.857);
      @extend .content-width;
      width: 1400px;
      position: relative;
      z-index: 10;
      .el-carousel__item {
        background-color: #fff;
        .swiper-item {
          width: 85.71%;
          margin: 50px auto 0px;
          display: flex;
          flex-wrap: wrap;
          img {
            width: 20%;
            height: 122px;
          }
        }
      }
      ::v-deep .el-carousel__indicators--horizontal {
        bottom: 24px;
      }

      ::v-deep .el-carousel__button {
        background-color: #d8dadf;
        opacity: 1;
        height: 4px;
      }
      ::v-deep .el-carousel__indicator.is-active .el-carousel__button {
        opacity: 1;
        background-color: #0075ff;
      }
    }
  }
  .news {
    padding: 70px 0px;

    .news-content {
      @extend .content-width;
      display: flex;
      height: 576px;
      justify-content: space-between;
      .news-left {
        width: 65.6%;
        background: #ffffff;
        box-shadow: 0px 6px 24px 0px rgba(0, 0, 0, 0.12);
        display: flex;
        flex-direction: column;
        .left-img {
          width: 100%;
          height: 340px;
          background: center/100% no-repeat;
        }

        .left-content {
          flex: 1;
          padding: 28px 30px 24px;
          position: relative;
          .title {
            font-size: 24px;
            font-family: "PingFangSC-Medium";
            color: #000000;
            line-height: 34px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .text {
            font-size: 16px;
            color: rgba(0, 0, 0, 0.65);
            line-height: 30px;
            overflow: hidden;
            text-overflow: ellipsis;
            word-break: break-all;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            margin: 12px 0px 12px;
          }
          .date {
            font-size: 12px;
            color: rgba(0, 0, 0, 0.45);
            line-height: 17px;
          }
          .btn {
            position: absolute;
            bottom: 24px;
            right: 30px;
            width: 112px;
            height: 36px;
            background: #0075ff;
            font-size: 18px;
            color: #ffffff;
            text-align: center;
            line-height: 36px;
            cursor: pointer;
            border: none;
            &:hover {
              background: #0077ffce;
            }
          }
        }
      }
      .news-right {
        width: 27.6%;
        .el-icon-arrow-down,
        .el-icon-arrow-up {
          font-weight: bold;
        }
        .arrow-allow {
          height: 40px;
          line-height: 40px;
          text-align: center;
          cursor: pointer;
          background-color: rgba(231, 242, 255, 1);
          color: rgba(0, 117, 255, 1);
          &:hover {
            background-color: #0176ff;
            color: #fff;
          }
        }
        .swiper-container {
          margin-top: 16px;
          height: 480px;
          width: 100%;
          .swiper-slide {
            width: 100%;
            .item-img {
              width: 100%;
              height: 144px;
              margin-bottom: 16px;
              position: relative;
              cursor: pointer;
              overflow: hidden;
              img {
                width: 100%;
                // height: 100%;
              }
              .item-title {
                position: absolute;
                background-color: rgba(0, 0, 0, 0.5);
                height: 40px;
                width: 100%;
                bottom: 0px;
                padding: 0px 12px;
                line-height: 40px;
                font-size: 14px;
                color: #ffffff;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
          }
          .active-swiper-slide {
            .item-img {
              .item-title {
                background-color: #0075ff;
              }
            }
          }
        }
      }
    }
  }
  .join-us {
    height: 360px;
    background: url("https://image.peerfintech.cn/peerfintech_website_v2/home/join-us-bg.png")
      center/cover no-repeat;
    text-align: center;
    .join-us-title {
      font-family: "ZhenyanGB";
      font-size: 40px;
      color: #ffffff;
      line-height: 40px;
      letter-spacing: 6px;
      padding-top: 209px;
    }
  }
}
</style>
